import $ from 'jquery';
export default class LiveChatActions {
    static onLiveChatAdded(callback) {
        const targetNode = document.body;
        const config = { childList: true, subtree: true };
        const observer = new MutationObserver(mutationsList => {
            for (const mutation of mutationsList) {
                if ($(mutation.addedNodes).filter('#spr-live-chat-app').length > 0) {
                    callback();
                    observer.disconnect();
                    break;
                }
            }
        });
        observer.observe(targetNode, config);
    }
}
